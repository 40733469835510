export default {
  NodeEnv: process.env.NODE_ENV,
  ApiServerUrl: process.env.API_SERVER_URL,
  Port: process.env.PORT,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  SENTRY_DSN: process.env.SENTRY_DSN,
  RELEASE_ID: process.env.RELEASE_ID,
  STRIPE_URL: process.env.STRIPE_URL
};
console.info(process.env.RELEASE_ID);
