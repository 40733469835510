/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import useLocales from '@/hooks/useLocales';
import Button from '@mui/material/Button';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import {FormControl, FormLabel, TextField, Grid} from '@mui/material';
import {toast} from 'react-toastify';
import {
  createPositionFields,
  updatePositionFields
} from '@/apis/PositionFields';
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';

interface ErrorResponse {
  non_field_errors: string[];
}
const SwitchField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [leftLabel, setLeftLabel] = React.useState('');
  const [rightLabel, setRightLabel] = React.useState('');
  const [newPositionField, setNewPositionField] = React.useState<string>('');
  const [fieldType, setFieldType] = React.useState('');

  React.useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split('/');
    setNewPositionField(segments[segments.length - 1]);
  });

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, helpText, rightLabel, leftLabel, description]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setDescription(props?.description || '');
      setHelpText(props?.help_text || '');
      setFieldType(props?.field_type);
    }
  }, [props]);

  const submitField = () => {
    props?.setRestoreFieldName('');
    if (
      label.length &&
      description.length &&
      leftLabel.length &&
      rightLabel.length
    ) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: 'boolean',
      description,
      help_text: helpText,
      choices: [leftLabel, rightLabel]
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    if (props.type === 'position_field') {
      setLoading(true);
      await createPositionFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await createFormFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };

  const updateField = async (payload: any) => {
    if (newPositionField === 'new_position_field') {
      setLoading(true);
      await updatePositionFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await updateFormFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };
  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('title')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('title')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('description')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={description}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('description')}
              error={Boolean(loading && description === '')}
              helperText={
                loading && description === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div className="mb-3 w-[300px]">
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <FormControl className="mb-5">
                <FormLabel className="text-[#212B36] mb-1">
                  {translate('left_label')}
                </FormLabel>
                <TextField
                  fullWidth={true}
                  value={leftLabel}
                  size="small"
                  className="bg-white"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLeftLabel(e.target.value);
                    setLoading(false);
                  }}
                  placeholder={translate('left_label')}
                  error={Boolean(loading && leftLabel === '')}
                  helperText={
                    loading && leftLabel === '' ? translate('required') : null
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <FormControl className="mb-5">
                <FormLabel className="text-[#212B36] mb-1">
                  {translate('right_label')}
                </FormLabel>
                <TextField
                  fullWidth={true}
                  value={rightLabel}
                  size="small"
                  className="bg-white"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setRightLabel(e.target.value);
                    setLoading(false);
                  }}
                  placeholder={translate('right_label')}
                  error={Boolean(loading && rightLabel === '')}
                  helperText={
                    loading && rightLabel === '' ? translate('required') : null
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <div>
          {(fieldType === 'standard' ||
            fieldType === 'dlubal' ||
            fieldType === 'client' ||
            fieldType === 'route') && (
            <div className="flex">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-dark-yellow"
                aria-hidden="true"
              />
              <span className="text-dark-yellow">
                {translate('not_editable')}
              </span>
            </div>
          )}
          <Button
            variant="contained"
            size="small"
            color="inherit"
            className="bg-[#144A6C] mr-5 px-7 h-[20px] text-white normal-case"
            onClick={submitField}
            disabled={
              (!validated && loading) ||
              fieldType === 'standard' ||
              fieldType === 'dlubal' ||
              fieldType === 'client' ||
              fieldType === 'route'
            }
          >
            {translate('save')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            className="bg-white mr-5 px-5 text-[#144A6C] h-[20px] normal-case border-0"
            onClick={() => {
              setLabel('');
              setDescription('');
              setHelpText('');
            }}
          >
            {translate('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
  // const payloadSwitch = {
  //   name: '',
  //   label: '',
  //   description: '',
  //   type: 'switch',
  // }
};

export default SwitchField;
